var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.id],
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c("ListFilter", { on: { applyFilter: _vm.applyFilter } }),
          _vm.allowManage
            ? _c(
                "div",
                { staticClass: "grid-toolbar border" },
                [
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_ADD_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.modalOpen(true)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--grid-toolbar-button)",
                                  },
                                  attrs: { icon: ["far", "plus"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canView()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_EDIT_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableEdit },
                                on: {
                                  click: function ($event) {
                                    return _vm.modalOpen(false)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "pen-to-square"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_EDIT_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_DUPLICATE_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableDuplicate },
                                on: { click: _vm.showDuplicateDialog },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clone"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_DUPLICATE_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.duplicate")) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canDelete()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_DELETE_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableDelete },
                                on: { click: _vm.rowDelete },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "trash-can"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_DELETE_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    { attrs: { id: "BTN_IMPORT_DOCUMENT_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        { on: { click: _vm.fileImport } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-in"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("project.button.import_document")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    { attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        { on: { click: _vm.fileExport } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-out"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("project.button.export_document")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                        _vm.colorMouseEnterEvent,
                        _vm.onColoringOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "coloring",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            id: "BTN_COLORING_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "palette"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1244906976
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("colorby") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("none")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("none")))]
                                  ),
                                  _vm.coloring.none
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("project")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.project")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.project
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("company")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("company.coloring.company")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.company
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("location")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.location")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.location
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("customer")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.customer")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.customer
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("status")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.status")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.status
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("rebate")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.rebate")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.rebate
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "project-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              pagination: "",
              paginationPageSize: 1000,
              cacheBlockSize: 10000,
              rowData: _vm.rowData,
              rowModelType: "serverSide",
              rowSelection: _vm.multiple ? "multiple" : "single",
              rowMultiSelectWithClick: "",
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _vm.projectShow
        ? _c("ProjectModal", {
            attrs: {
              id: _vm.projectId,
              show: _vm.projectShow,
              title: _vm.projectTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.projectShow = $event
              },
              success: _vm.projectSuccess,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("project.field.name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("project.field.name"),
                      "data-vv-name": "duplicate.name",
                      "data-vv-delay": "500",
                      trim: "",
                    },
                    model: {
                      value: _vm.duplicateName,
                      callback: function ($$v) {
                        _vm.duplicateName = $$v
                      },
                      expression: "duplicateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      model: {
                        value: _vm.includeTasks,
                        callback: function ($$v) {
                          _vm.includeTasks = $$v
                        },
                        expression: "includeTasks",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("project.duplicate_tasks")))]
                  ),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDuplicateNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " + _vm._s(_vm.errors.first("duplicate.name")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("project.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "project.confirmation.delete_plural"
                      : "project.confirmation.delete"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("GanttImportDialog", {
        attrs: {
          properties: [
            { value: "color", text: _vm.$t("field.color") },
            { value: "company", text: _vm.$t("project.field.company") },
            {
              value: "currencycode",
              text: _vm.$t("project.field.currencyCode"),
            },
            { value: "customer", text: _vm.$t("project.field.customer") },
            { value: "description", text: _vm.$t("project.field.description") },
            {
              value: "closetime",
              text: _vm.$t("project.field.scheduleFinish"),
            },
            { value: "fixedCost", text: _vm.$t("project.field.fixedCost") },
            { value: "identifier", text: _vm.$t("field.identifier") },
            { value: "location", text: _vm.$t("project.field.location") },
            { value: "name", text: _vm.$t("project.field.name") },
            { value: "priority", text: _vm.$t("project.field.priority") },
            { value: "rebates", text: _vm.$t("task.field.rebates") },
            {
              value: "schedulemode",
              text: _vm.$t("project.field.scheduleMode"),
            },
            { value: "stages", text: _vm.$t("project.field.stages") },
            { value: "starttime", text: _vm.$t("project.field.scheduleStart") },
            { value: "status", text: _vm.$t("project.field.status") },
            { value: "tag", text: _vm.$t("project.field.tag") },
          ],
          mode: "PROJECT",
          show: _vm.docImportShow,
          title: _vm.$t("project.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }