<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
          <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('project.button.import_document') }}
        </b-popover>
        <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
          <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('project.button.export_document') }}
        </b-popover>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('project')" href="#">
                <span class="action-item-label">{{ $t('project.coloring.project') }}</span><font-awesome-icon class="active-check" v-if="coloring.project" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('company')" href="#">
                <span class="action-item-label">{{ $t('company.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location')" href="#">
                <span class="action-item-label">{{ $t('project.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('customer')" href="#">
                <span class="action-item-label">{{ $t('project.coloring.customer') }}</span><font-awesome-icon class="active-check" v-if="coloring.customer" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('status')" href="#">
                <span class="action-item-label">{{ $t('project.coloring.status') }}</span><font-awesome-icon class="active-check" v-if="coloring.status" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('rebate')" href="#">
                <span class="action-item-label">{{ $t('project.coloring.rebate') }}</span><font-awesome-icon class="active-check" v-if="coloring.rebate" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="project-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <ProjectModal v-if="projectShow" :id="projectId" :show.sync="projectShow" @success="projectSuccess" :title="projectTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('project.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('project.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-input-group class="mt-1">
          <b-form-checkbox v-model="includeTasks">{{ $t('project.duplicate_tasks') }}</b-form-checkbox>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('project.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'project.confirmation.delete_plural':'project.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'company', text: $t('project.field.company') }, { value: 'currencycode', text: $t('project.field.currencyCode') }, { value: 'customer', text: $t('project.field.customer') }, { value: 'description', text: $t('project.field.description') }, { value: 'closetime', text: $t('project.field.scheduleFinish') }, { value: 'fixedCost', text: $t('project.field.fixedCost') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'location', text: $t('project.field.location') }, { value: 'name', text: $t('project.field.name') }, { value: 'priority', text: $t('project.field.priority') }, { value: 'rebates', text: $t('task.field.rebates') }, { value: 'schedulemode', text: $t('project.field.scheduleMode') }, { value: 'stages', text: $t('project.field.stages') }, { value: 'starttime', text: $t('project.field.scheduleStart') }, { value: 'status', text: $t('project.field.status') }, { value: 'tag', text: $t('project.field.tag') }]" :mode="'PROJECT'" :show="docImportShow"
      :title="$t('project.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common'
import alertStateEnum from '@/enums/alert-state';
import { strRandom, addTags, invertColor, getFirstColor, costFormat, costFormatAdv } from '@/helpers';
import { projectService, viewProfileService, projectLinkLocationService, 
         projectLinkCompanyService, projectLinkRebateService, projectLinkCustomerService, 
         projectLinkStageService, projectLinkStatusService, projectLinkTagService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const p = self.buildParams(params);
      if(self.isFirstLoad && self.selectedId != null) {
        p.sight = self.selectedId;
      }
      projectService.list(p, true, true).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);

        //Preselect provided project on modal show.
        if(self.isFirstLoad && self.selectedId != null) {
          self.selected.push(self.selectedId);
          const rowIndex = response.arg_index;
          const pageSize = self.gridApi.paginationGetPageSize();
          const currentPage = parseInt(rowIndex / pageSize) + 1;
          self.gridApi.paginationGoToPage(currentPage);
          params.api.refreshServerSide({purge: true});
          self.isFirstLoad = false;
          return;
        }

        if(self.gridApi && self.modalShow) {
          self.gridApi.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridApi.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
        }
        self.gridApi.hideOverlay();
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      })
      .catch(e => {
        params.successCallback([], 0);
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'ProjectSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    ProjectModal: () => import('@/components/modal/ProjectModal.vue'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),

    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'costCellRenderer': CostCellRenderer,
    'durationCellRenderer': DurationCellRenderer,
    'colorCellRenderer': ColorCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */    
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    selectedId: {
      type: String,
      default: null
    },
    projectIds: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      id: `PROJECT_LIST_${strRandom(5)}`,
      permissionName: 'PROJECT',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
      // modules: AllModules,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],

      projectId: null,
      projectShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: '',

      duplicateShow: false,
      duplicateName: null,
      includeTasks: false,
      duplicateInProgress: false,
      
      docImportShow: false,
      
      coloring: {
        none: true,
        project: false,
        company: false,
        location: false,
        customer: false,
        status: false,
        rebate: false
      },
      lastOpenColumnMenuParams: null,

      noRowsMessage: null,
      noRowsOverlayComponentParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const self = this;
    const profileKey = 'project_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    const colDefs = [
      {
        headerName: this.$t('project.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        cellRendererParams: {
          ignoreAvatar: true
        },
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc',
        cellStyle: params => {
            if (params.data &&
                params.data.color &&
                self.coloring.project) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
            }
            else if (params.data &&
                params.data.companyColor &&
                self.coloring.company) {
                      const color = getFirstColor(params.node.data.companyColor);
                      if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.locationColor &&
                self.coloring.location) {
                      const color = getFirstColor(params.node.data.locationColor);
                      if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.customerColor &&
                self.coloring.customer) {
                      const color = getFirstColor(params.node.data.customerColor);
                      if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.statusColor &&
                self.coloring.status) {
                      const color = getFirstColor(params.node.data.statusColor);
                      if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.rebateColor &&
                self.coloring.rebate) {
                      const color = getFirstColor(params.node.data.rebateColor);
                      if (color) return { background: color, color: invertColor(color, true) };
            }
        }
      },
      {
        headerName: this.$t('project.field.customer'),
        field: 'customer',
        hide: false
      },
      {
        headerName: this.$t('project.field.progress'),
        field: 'progressPercent',
        hide: false
      },
      {
        headerName: this.$t('project.field.scheduleStart'),
        field: 'scheduleStart',
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('project.field.scheduleFinish'),
        field: 'scheduleFinish',
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('project.field.estimatedDuration'),
        field: 'estimatedDuration',
        cellRenderer: 'durationCellRenderer',
        cellRendererParams: {
          unit: 'days'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.fixedDuration'),
        field: 'fixedDuration',
        cellRenderer: 'durationCellRenderer',
        cellRendererParams: {
          unit: 'days'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.company'),
        field: 'company'
      },
      {
        headerName: this.$t('project.field.location'),
        field: 'location'
      },
      {
        headerName: this.$t('project.field.priority'),
        field: 'priority',
        hide: true
      },
      {
        headerName: this.$t('project.field.scheduleMode'),
        field: 'scheduleMode',
        hide: true
      },
      {
        headerName: this.$t('project.field.description'),
        field: 'description',
        hide: true
      },
      {
        headerName: this.$t('project.field.currencyCode'),
        field: 'currencyCode',
        hide: true
      },
      {
        headerName: this.$t('project.field.fixedCost'),
        field: 'fixedCost',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.fixedCostNet'),
        field: 'fixedCostNet',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.actualCost'),
        field: 'actualCost',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.actualCostNet'),
        field: 'actualCostNet',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.estimatedCost'),
        field: 'estimatedCost',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.estimatedCostNet'),
        field: 'estimatedCostNet',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        hide: true
      },
      {
        headerName: this.$t('project.field.rebates'),
        field: 'rebates',
        cellRenderer: 'rebateCellRenderer'
      },
      {
        headerName: this.$t('project.field.status'),
        field: 'status',
        hide: true
      },
      {
        headerName: this.$t('project.field.stages'),
        field: 'stages',
        hide: true
      },
      {
        headerName: this.$t('project.field.tag'),
        field: 'tag',
        hide: true
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        hide: true
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier'
      }
    ];
    
    const linkedEntities = [
      { selector: 'PROJECT.CUSTOMER', field: 'customer', properties: ['name'] },
      { selector: 'PROJECT.COMPANY', field: 'company', properties: ['name'] },
      { selector: 'PROJECT.LOCATION', field: 'location', properties: ['name'] },
      { selector: 'PROJECT.STAGE', field: 'stage', properties: ['name'] },
      { selector: 'PROJECT.REBATE', field: 'rebate', properties: ['name'] },
      { selector: 'PROJECT.TAG', field: 'tag', properties: ['name'] },
      { selector: 'PROJECT', field: 'fixedCost', properties: ['currencyCode'] },
      { selector: 'PROJECT', field: 'fixedCostNet', properties: ['currencyCode'] },
      { selector: 'PROJECT', field: 'actualCost', properties: ['currencyCode'] },
      { selector: 'PROJECT', field: 'actualCostNet', properties: ['currencyCode'] },
      { selector: 'PROJECT', field: 'estimatedCost', properties: ['currencyCode'] },
      { selector: 'PROJECT', field: 'estimatedCostNet', properties: ['currencyCode'] }
    ]
    
    this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
      filterOutViewDenyProperties(colDefs, 'PROJECT', linkedEntities);
      colDefs.sort(columnDefSortFunc);
      this.columnDefs = colDefs;
    });

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
    this.isFirstLoad = true;
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
  },
  watch: {
    show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.isFirstLoad = true;
        this.loadViewProfile();
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    projectTitle() {
      return this.projectId && this.projectId.indexOf('PROJECT_NEW') == -1? this.$t('project.title_detail'): this.$t('project.title_new');
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('project.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('project.title_duplicate');
    },
    companyrule() {
      if (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyrule = ['PROJECT.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const self = this;
    
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId === 'progressPercent' ? 'progress' : sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      
      const companyrule = this.companyrule;
      if (companyrule) {
        if (params.filter === '') {
          params.filter = [companyrule];
        }
        else {
          params.filter = [
            '_and_', [
              ['PROJECT.name', 'regex', this.searchFilter],
              companyrule
            ]
          ];
        }
      }

      if (this.projectIds.length > 0) {
        const rule = ['PROJECT.uuId', 'within', this.projectIds.join('|')];
        if (params.filter === '') {
          params.filter = [rule];
        } else {
          params.filter.push(rule);
        }
        
      }
      
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.projectId = `PROJECT_NEW_${strRandom(5)}`;
      } else {
        this.projectId = this.selected[0];
      }
      this.projectShow = true;
      this.resetAlert();
    },
    projectSuccess(payload) {
      this.gridApi.refreshServerSide({ purge: true });
      this.alertPositive = true;
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('project.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`project.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await projectService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('project.delete_partial');
          alertMsgDetailTitle = this.$t(`project.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`project.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`project.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      
      const details = this.gridApi.getSelectedNodes().map(i => { 
        const stageList = [];
        if (i.data.stages) {
          for (let j = 0; j < i.data.stages.length; j++) {
            stageList.push({ uuId: i.data.stagesUuId[j], name: i.data.stages[j] });
          }
        }
        return {uuId: i.data.uuId, name: i.data.name, color: i.data.color, stageList: stageList, autoScheduling: i.data.autoScheduling} 
      });
      this.$emit('ok', { ids: [...this.selected], details: details });
      this.$emit('input', [...this.selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    openDetail(id){
      this.projectId = id;
      this.projectShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.includeTasks = false;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('project.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          projectService.clone(this.selected[0], this.includeTasks, { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('project.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.PROJECT')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('project.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.project_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.project_selector_list);
            self.coloring.none = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.none : true;
            self.coloring.project = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.project : false;
            self.coloring.company = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.company : false;
            self.coloring.location = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.location : false;
            self.coloring.customer = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.customer : false;
            self.coloring.status = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.status : false;
            self.coloring.rebate = self.settings.project_selector_coloring ? self.settings.project_selector_coloring.rebate : false;
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }

      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(/**self*/) {
      const formatCost = (value, currencyCode=null) => {
        let rawValue = parseInt(value);
        if (rawValue < 0) {
          return '';
        }
        else {
          return currencyCode == null? `$${costFormat(rawValue, {notation:'standard'})}` : costFormatAdv(rawValue, currencyCode, {notation:'standard'});
        }
      }
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        else if (params.column.colId.indexOf('scheduleStart') !== -1) {
          if (params.node.data.scheduleStart === 0) {
            return '';
          }
          return moment(params.node.data.scheduleStart).format();
        }
        else if (params.column.colId.indexOf('scheduleFinish') !== -1) {
          if (params.node.data.scheduleFinish === 0) {
            return '';
          }
          return moment(params.node.data.scheduleFinish).format();
        }
        else if (params.column.colId.indexOf('rebates') !== -1) {
          return params.value !== null && params.value ? params.value.map(n => { return `${n.name}(${n.rebate*100}%)` }).join(', ') : '';
        }
        else if (params.column.colId.indexOf('stages') !== -1) {
          return params.value !== null && params.value ? params.value.join(', ') : '';
        }
        else if (params.column.colId.indexOf('estimatedCost') !== -1 || params.column.colId.indexOf('actualCost') !== -1 ||
                   params.column.colId.indexOf('fixedCost') !== -1 ||
                   params.column.colId.indexOf('Cost') !== -1) {
          const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
          return formatCost(params.value, currencyCode)
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
    
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
      
        const self = this;
        this.gridApi.exportDataAsExcel({ 
          fileName: 'Projects'
          , sheetName: 'Projects'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
        
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridApi.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addProjects(items);
      this.inProgressShow = false;
      this.gridApi.refreshServerSide({ purge: true });
    }, 
    parseCost(val) {
      if (typeof val === 'undefined') {
        return null;
      }
      
      if (val.indexOf('$') === 0) {
        val = val.substr(1);
      }
      
      var num = parseFloat(val);
      if (val.includes('k')) {
        num = num * 1000;
      }
      return `${num}`;
    },
    async addProjects(items) {
      this.inProgressLabel = this.$t('project.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        const starttime = typeof item.starttime === 'string' ? moment.utc(item.starttime, 'YYYY-MM-DD').valueOf() : item.starttime;
        const closetime = typeof item.closetime === 'string' ? moment.utc(item.closetime, 'YYYY-MM-DD').valueOf() : item.closetime;
        const data = {
          name: item.name,
          autoScheduling: true,
          scheduleStart: item.starttime != null && starttime > 0 ? starttime : null,
          scheduleFinish: item.closetime != null && closetime > 0 ? closetime : null,
          scheduleFrom: item.scheduleFrom !== null ? item.scheduleFrom : null,
          priority: item.priority,
          currencyCode: item.currencycode,
          description: item.description,
          fixedCost: item.fixedCost ? this.parseCost(item.fixedCost) : null,
          identifier: item.identifier,
          color: item.color
        }
        
        const result = await projectService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result) {
          if (item.location && item.location.uuId) {
            await projectLinkLocationService.create(result, [item.location.uuId])
          }
          
          if (item.tag) {
            await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), projectLinkTagService);
          }
          
          if (item.company && item.company.uuId) {
            const company = item.company;
            await projectLinkCompanyService.create(result, company.uuId);
          }
          else {
            const masterCompanyId = localStorage.companyId;
            await projectLinkCompanyService.create(result, masterCompanyId);
          }
          
          if (item.customer && item.customer.uuId !== null) {
            await projectLinkCustomerService.create(result, [item.customer])
          }
          
          if (item.rebates && item.rebates.length > 0 && item.rebates[0].uuId !== null) {
            await projectLinkRebateService.create(result, item.rebates);
          }
          
          if (item.stages && item.stages.length > 0 && item.stages[0].uuId !== null) {
            await projectLinkStageService.create(result, item.stages);
          }
          
          if (item.status && item.status.uuId !== null) {
            await projectLinkStatusService.create(result, [item.status]);
          }
        }
        percentage++;
        this.inProgressLabel = this.$t('project.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['project_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.project) {
        return data.color;
      }
      else if (data &&
        data.companyColor &&
        data.companyColor.length > 0 &&
        data.companyColor[0] !== '' &&
        this.coloring.company) {
        return data.companyColor[0];
      }
      else if (data &&
        data.locationColor &&
        data.locationColor.length > 0 &&
        data.locationColor[0] !== '' &&
        this.coloring.location) {
        return data.locationColor[0];
      }
      else if (data &&
        data.customerColor &&
        data.customerColor.length > 0 &&
        data.customerColor[0] !== '' &&
        this.coloring.customer) {
        return data.customerColor[0];
      }
      else if (data &&
        data.statusColor &&
        data.statusColor.length > 0 &&
        data.statusColor[0] !== '' &&
        this.coloring.status) {
        return data.statusColor[0];
      }
      else if (data &&
        data.rebateColor &&
        data.rebateColor.length > 0 &&
        data.rebateColor[0] !== '' &&
        this.coloring.rebate) {
        return data.rebateColor[0];
      }
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.project_grid_no_data`)
    }
  }
}


</script>